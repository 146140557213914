<template>
  <!-- SECTION 0: NAVIGATION -->
  <nav
    id="navbarExample"
    class="navbar navbar-expand-lg fixed-top navbar-light"
    aria-label="Main navigation"
  >
    <div class="container">
      <a class="navbar-brand logo-text" href="#">$SIGMA</a>
      <button
        class="navbar-toggler p-0 border-0"
        type="button"
        id="navbarSideCollapse"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="navbar-collapse offcanvas-collapse"
        id="navbarsExampleDefault"
      >
        <ul class="navbar-nav ms-auto navbar-nav-scroll">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#buy">How to Buy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#roadmap">Roadmap</a>
          </li>
        </ul>
        <span class="nav-item">
          <a
            class="btn-solid-sm"
            href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x733590E6880c388C11b0d82d94a14Ef19107025C&chain=base"
            target="_blank"
            >Join The Gang</a
          >
        </span>
      </div>
    </div>
  </nav>
  <!-- END OF SECTION 0: NAVIGATION -->

  <!-- SECTION 1: HOME -->
  <div id="home" class="home">
    <div class="cloud-top"></div>
    <div class="cloud-bottom"></div>
    <div class="row">
      <div class="col-lg-6 col-xl-6 coin-img-container d-flex">
        <img src="assets/images/coin.png" alt="alternative" />
      </div>
      <div
        class="col-lg-6 col-xl-6 home-text-container d-flex align-items-center"
      >
        <div class="text-container">
          <h3 class="h3-large">No Alpha. No Beta. Only</h3>
          <h1 class="h1-large">$SIGMA</h1>
          <a
            class="btn-cta"
            href="https://dexscreener.com/base/0x733590E6880c388C11b0d82d94a14Ef19107025C"
            target="_blank"
            >More About $SIGMA</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- END OF SECTION 1: HOME -->

  <!-- SECTION 2: ABOUT -->
  <div id="about" class="about">
    <div class="row">
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
    </div>

    <div class="about-container">
      <div class="about-box">
        <h2>Introducing Sigma Coin</h2>

        <div class="about-paragraph">
          <p>
            The fresh meme coin that blends classic arcade gaming excitement
            with crypto innovation. Our lively logo features a sharp-edged 'S'
            with a vertical stroke, like a lightning bolt, sparking fun and
            energy. Dive into a dynamic community where nostalgia and
            cutting-edge tech collide!
          </p>
        </div>

        <h2>The Sigma Mission</h2>

        <div class="about-paragraph">
          <p>
            Our mission is to infuse the crypto space with fun, creativity, and
            a dash of humor. We aim to make cryptocurrency investing an engaging
            and enjoyable experience for everyone. Join our vibrant community
            and be part of the excitement!
          </p>
        </div>

        <h2>Why Us</h2>

        <div class="about-paragraph">
          <p>
            Sigma Coin is all about community, fun, and innovation. We make
            crypto accessible and enjoyable for everyone, from beginners to
            seasoned investors, with engaging events and creative rewards. Plus,
            our decentralized network ensures fairness and transparency in every
            transaction.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
      <div class="col-2 coin-img-container d-flex justify-content-center">
        <img src="assets/images/coin_spin.gif" alt="alternative" />
      </div>
    </div>
  </div>

  <!-- END OF SECTION 2: ABOUT -->

  <!-- SECTION 3: HOW TO BUY -->
  <div id="buy" class="buy">
    <h2>Be Wholesome Today</h2>
    <h3>How To Join The Sigma Gang</h3>
    <br />
    <br />

    <div class="row">
      <div class="col-lg-4 col-md-12 d-flex justify-content-center">
        <div class="card card-1">
          <div class="number-container">
            <h4>01</h4>
          </div>
          <h4>Create A Wallet</h4>

          <p>
            To start, you need to create a cryptocurrency wallet that supports
            the Base chain, such as MetaMask. Download the MetaMask extension,
            follow the setup instructions, add the Base network to your wallet,
            and securely store your recovery phrase.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 d-flex justify-content-center">
        <div class="card card-2">
          <div class="number-container number-container-2">
            <h4>02</h4>
          </div>
          <h4>Get $WETH</h4>
          <p>
            Next, you need to acquire Wrapped Ethereum ($WETH) compatible with
            the Base chain. Purchase ETH on an exchange like Coinbase, transfer
            it to your MetaMask wallet, and use a bridge service to convert your
            ETH to $WETH on the Base chain.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 d-flex justify-content-center">
        <div class="card card-3">
          <div class="number-container">
            <h4>03</h4>
          </div>
          <h4>Swap To $SIGMA</h4>
          <p>
            Go to a Base-compatible DEX like Uniswap. Connect your wallet,
            select Base chain, select $WETH to swap, enter the $SIGMA token
            address: <br />
            <u>0x733590E6880c388C11b0d82d94a14Ef19107025C</u> <br />specify the
            amount, and complete the transaction.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- END OF SECTION 3: HOW TO BUY -->

  <!-- SECTION 4: ROADMAP -->
  <div id="roadmap" class="roadmap">
    <h2>Roadmap</h2>
    <h3>The Future of $SIGMA</h3>
    <br />
    <br />
    <br />

    <div class="timeline-container">
      <!-- PHASE 1 -->
      <div class="row g-0">
        <div class="col-4 roadmap-col-text-right d-flex">
          <div>
            <h2>Phase 1</h2>
            <div class="roadmap-paragraph">
              <p>
                We are launching $SIGMA, aiming to trend on Twitter and reach
                1000+ holders, while building a sleek website and strong social
                media presence to engage our community.
              </p>
            </div>
          </div>
        </div>
        <div class="col-4 roadmap-col-image d-flex justify-content-center">
          <img
            class="timeline-image"
            src="assets/images/timeline_dot1.png"
            alt="alternative"
          />
        </div>
        <div
          class="col-4 roadmap-col-image d-flex justify-content-center"
        ></div>
      </div>

      <!-- PHASE 2 -->
      <div class="row g-0">
        <div
          class="col-4 roadmap-col-text-right d-flex justify-content-center"
        ></div>
        <div class="col-4 roadmap-col-image d-flex justify-content-center">
          <img
            class="timeline-image"
            src="assets/images/timeline_dot1.png"
            alt="alternative"
          />
        </div>
        <div class="col-4 roadmap-col-text-left d-flex justify-content-center">
          <div>
            <h2>Phase 2</h2>
            <div class="roadmap-paragraph">
              <p>
                We will form strategic community partnerships, get $SIGMA listed
                on CoinGecko and CoinMarketCap, and aim for 10,000+ holders.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- PHASE 3 -->
      <div class="row g-0 phase-3">
        <div class="col-4 roadmap-col-text-right d-flex">
          <h2>Phase 3</h2>
          <div class="roadmap-paragraph">
            <p>
              We will launch Sigma merchandise, conduct an airdrop, grow to
              100,000+ holders, and become the Base best "Coin" meme.
            </p>
          </div>
        </div>
        <div class="col-4 roadmap-col-image-last d-flex justify-content-center">
          <img
            class="last-image"
            src="assets/images/timeline_dot2.png"
            alt="alternative"
          />
        </div>
        <div
          class="col-4 roadmap-col-image d-flex justify-content-center"
        ></div>
      </div>
    </div>
  </div>
  <!-- END OF SECTION 4: ROADMAP -->

  <br />
  <br />
  <!-- FOOTER -->
  <footer>
    <div class="footer-container">
      <p>&copy; 2024 Sigma Coin</p>
    </div>
  </footer>

  <button id="myBtn">
    <img src="assets/images/up-arrow.png" alt="alternative" />
  </button>
</template>
